// import { toggleRfqFormModal } from '@/redux/actions';
// import { useSSRSelector } from '@/redux/ssrStore';
// import { STORE_CUSTOMIZATION } from '@/utils/constants';
// import { deviceWidth } from '@/utils/deviceWidth';
// import { getStoreType } from '@/utils/getStoreType';
// import { getRoute, PATH } from '@/utils/routes';
// import { useRouter } from 'next/router';
// import { useState } from 'react';
// import { useDispatch } from 'react-redux';

import { useGetNavList } from '@/components/DesignSystem/Drawer/MenuDrawerMobile/utils';
import { useSSRSelector } from '@/redux/ssrStore';
import { removeEmoticons } from '@/utils/emoticonsStatus';
import { isPLPRequiredSystem } from '@/utils/isPLPRequiredSystem';
import { getRoute, PATH } from '@/utils/routes';
import { routeToCollectionPage } from '@/utils/routeToCollectionPage';
import router from 'next/router';
import { useMemo } from 'react';
import store from 'src/redux/store';

const collections = [
  {
    id: 11413,
    store_id: 0,
    name: 'UNSTICHED SALWARS',
    image_url: 'https://cdn.dotpe.in/longtail/item_collection/8504379/QnHA8889.webp',
    thumbnail_url: '',
    status: 'A',
    rank: 2,
    categories: [
      {
        id: 6009758,
        name: 'DESIGNER SALWAR SUITS',
        master_sub_category: 0,
        image_url: '',
        active_products: 69,
        selected_products: 69,
        full_category: true,
        products_config: null,
      },
      {
        id: 6009759,
        name: 'PRINTED SALWAR SUITS',
        master_sub_category: 0,
        image_url: '',
        active_products: 4,
        selected_products: 4,
        full_category: true,
        products_config: null,
      },
      {
        id: 6009760,
        name: 'SILK SALWAR SUITS',
        master_sub_category: 0,
        image_url: '',
        active_products: 26,
        selected_products: 26,
        full_category: true,
        products_config: null,
      },
    ],
  },
  {
    id: 11414,
    store_id: 0,
    name: 'EXQUISITE SAREES',
    image_url: 'https://cdn.dotpe.in/longtail/item_collection/8504379/XnlyTeal.webp',
    thumbnail_url: '',
    status: 'A',
    rank: 3,
    categories: [
      {
        id: 6009761,
        name: 'BRIDAL SILK SAREES',
        master_sub_category: 0,
        image_url: '',
        active_products: 55,
        selected_products: 55,
        full_category: true,
        products_config: null,
      },
      {
        id: 6009762,
        name: 'FESTIVE SILK SAREES',
        master_sub_category: 0,
        image_url: '',
        active_products: 113,
        selected_products: 113,
        full_category: true,
        products_config: null,
      },
      {
        id: 6009763,
        name: 'WORK WEAR SAREES',
        master_sub_category: 0,
        image_url: '',
        active_products: 83,
        selected_products: 83,
        full_category: true,
        products_config: null,
      },
    ],
  },
  {
    id: 11415,
    store_id: 0,
    name: 'ELEGANT DUPATTAS',
    image_url: 'https://cdn.dotpe.in/longtail/item_collection/8504379/Ce9m4fYU.webp',
    thumbnail_url: '',
    status: 'A',
    rank: 4,
    categories: [
      {
        id: 6010251,
        name: 'PRINTED DUPATTAS',
        master_sub_category: 0,
        image_url: '',
        active_products: 5,
        selected_products: 5,
        full_category: true,
        products_config: null,
      },
    ],
  },
];

// const IconList = [
//   {
//     name: 'Search',
//     imgSrc: '/assets/icons/search.svg',
//     href: PATH.SEARCH,
//   },
//   {
//     name: 'Contact Us',
//     imgSrc: '/assets/icons/whatsapp.svg',
//     href: false,
//   },
//   {
//     name: 'Cart',
//     imgSrc: '/assets/icons/shopping-cart.svg',
//     href: PATH.CART,
//   },
//   {
//     name: 'orders',
//     imgSrc: '/assets/icons/user.svg',
//     href: PATH.ORDERS,
//   },
// ];

// const TempIconList = [
//   {
//     name: 'Contact Us',
//     imgSrc: '/assets/icons/whatsapp.svg',
//     href: false,
//   },
// ];
// function useHeaderUtils() {
//   const isDesktop = deviceWidth > 766 ? true : false;
//   if (isDesktop) IconList.splice(1, 1);

//   const router = useRouter();
//   const dispatch = useDispatch();
//   const { storeData, userData } = useSSRSelector((state) => ({
//     storeData: state.storeReducer.store,
//     userData: state.userReducer,
//   }));
//   const { services } = storeData;

//   const [showDesktopDropdown, setShowDesktopDropdown] = useState(false);

//   const handleLogoClick = () =>
//     router.push(getRoute(PATH.PRODUCT, storeData?.store_info?.domain));

//   const handleCartClick = () => {
//     if (getStoreType({ services }) === STORE_CUSTOMIZATION.B2B) {
//       dispatch(toggleRfqFormModal());
//       return;
//     }
//     router.push(getRoute(PATH.CART, storeData?.domain));
//   };

//   function toggleShowDesktopDropdown() {
//     setShowDesktopDropdown(!showDesktopDropdown);
//   }

//   function onHandleOrdersClick(href) {
//     if (userData?.isLogin && href === PATH.ORDERS && deviceWidth > 766) {
//       toggleShowDesktopDropdown();
//     } else {
//       router.push(getRoute(PATH.ORDERS, storeData?.domain));
//     }
//   }

//   const onContactBtnClick = () => {
//     window.location.href = `whatsapp://send?phone=+91${storeData?.owner?.phone}&text=Hi%2C%0D%0A%0D%0AI+am+at+your+online+store+and+need+some+help.%0D%0A%0D%0A${storeData?.store_url}`;
//   };

//   const gotoStoreHome = () => {
//     router.push(getRoute(PATH.PRODUCT, storeData?.domain));
//   };

//   const onHandlePageClick = (href) => {
//     if (href === PATH.CART && getStoreType(storeData) === STORE_CUSTOMIZATION.B2B) {
//       dispatch(toggleRfqFormModal());
//       return;
//     }
//     if (userData?.isLogin && href === PATH.ORDERS && isDesktop) {
//       toggleShowDesktopDropdown();
//     } else if (href) {
//       router.push(getRoute(href, storeData?.store_info?.domain));
//     } else {
//       window.open(
//         `https://api.whatsapp.com/send?phone=+91${
//           storeData?.display_phone || storeData?.owner?.phone
//         }&text=Hi%2C%0D%0A%0D%0AI+am+at+your+online+store+and+need+some+help.%0D%0A%0D%0A${
//           storeData?.store_info?.store_url
//         }`
//       );
//     }
//   };

//   // const toggleAuthModal = () => {
//   //   if (
//   //     commonToggleAuthModal({
//   //       userLogin: userData?.isLogin,
//   //       storeInfoDomain: storeData?.domain,
//   //     })
//   //   )
//   //     toggleLogoutModal();
//   // };
// }
const generateCategoryMenuItem = (categories) => {
  const storeData = store.getState()?.storeReducer?.store;
  const onCategoriesLinkHandle = (category) => {
    router.push(
      getRoute(
        `${
          isPLPRequiredSystem(storeData?.theme?.theme_class) ? PATH.SHOP : PATH.PRODUCT
        }?cid=${category.id}`,
        storeData?.store_info?.domain
      ),
      undefined,
      { shallow: true }
    );
  };

  const categoriesMap = categories.reduce((coll, item) => {
    coll[item.id] = {
      name: removeEmoticons(item.name) || 'Available Items',
      showOnHeader: item.status ?? 1,
      type: 'text',
      onClick: () => onCategoriesLinkHandle(item),
      id: item.id,
    };
    return coll;
  }, {});
  return categoriesMap;
};

export function useGetCategoriesList() {
  const { productTags, categories } = useSSRSelector((state) => ({
    categories: state.catalogReducer.categories,
    productTags: state.catalogReducer.productTags,
  }));
  return useMemo(() => {
    const concatCategories = [...(productTags || []), ...(categories || [])];
    if (!concatCategories?.length) return {};
    return generateCategoryMenuItem(concatCategories);
  }, [productTags, categories]);
}

export const useOldNavLinksList = () => {
  const { navigationList, productTags, categories } = useSSRSelector((state) => ({
    navigationList: state.additionalPagesReducer.navigationList,
    categories: state.catalogReducer.categories,
    productTags: state.catalogReducer.productTags,
    collections: state.catalogReducer.collections,
  }));

  const oldNavItemsList = useGetNavList(navigationList);

  const categoriesList = useGetCategoriesList();

  const collectionCategoriesList = useMemo(() => {
    if (!collections?.length) return {};
    const collectionMap = collections?.reduce((coll, item) => {
      coll[item.id] = {
        name: removeEmoticons(item.name),
        showOnHeader: item.status === 'A',
        type: 'text',
        onClick: () => routeToCollectionPage(item),
        option: generateCategoryMenuItem(item.categories),
      };
      return coll;
    }, {});
    return collectionMap;
  }, [productTags, categories, collections]);

  const defaultNavItemsList = useMemo(() => {
    const shopDrowdownList = Object.values(categoriesList);
    const list = (function convertOldNavListToNew(oldNavItemsList) {
      return oldNavItemsList?.map((item) => {
        const isDropdownAvailable =
          item.subNavs?.length || (item.page_type === 'shop' && shopDrowdownList?.length);

        return {
          redirectionLink: !isDropdownAvailable ? item.slug_url : '',
          name: item.page_name,
          showOnHeader: item.status ?? 1,
          option: item.subNavs
            ? convertOldNavListToNew(item.subNavs)
            : item.page_type === 'shop'
              ? shopDrowdownList
              : null,
          type: isDropdownAvailable ? 'dropdown' : 'text',
          isDefaultNavList: true,
          id: item.page_id,
          isShopComponent: item.page_type === 'shop',
        };
      });
    })(oldNavItemsList);
    return list;
  }, [productTags, categories, oldNavItemsList]);

  return { defaultNavItemsList, categoriesList, collectionCategoriesList };
};
